/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: #f1f5f8;
    color: #222;
  }
  
  .recommendlist {
    width: 90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem;
  } */
  
  @media screen and (min-width: 768px) {
    .recommendlist {
      grid-template-columns: repeat(3, 1fr);
      align-items: start;
    }
  }
  .recommend {
    background: #fff;
    border-radius: 1rem;
    padding: 2rem;
    text-align: left;
  }
  .recommend img {
    width: 33%;   
    height: 33%;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .recommend h2 {
    margin-top: 1rem;
    font-size: 1rem;
  }
  
  .recommend h4 {
    color: #617d98;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    letter-spacing: 2px;
  }
  .recommend p {
    margin: 1rem 0 0.5rem;
  }
  h1 {
    text-align: center;
    margin-top: 4rem;
    text-transform: capitalize;
  }
  