/* header styles */

.header {
  display: block;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}

.cartButton {
  color: white;
  background-color: rgb(193, 116, 8);
  width: 95%;
  margin: 0 10px;
}

.cartButton :hover {
  background-color: rgba(174, 97, 10, 0.208) !important;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 20px;
}

.cartItemImg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.cartItemDetail {
  display: flex;
  flex: 1;
  padding: 0 20px;
  flex-direction: column;
}

/* Home Page */

.home {
  display: flex;
}

.filters {
  background-color: rgb(193, 116, 8);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
  height: 86vh;
}

.filters > span {
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
}

.productContainer {
  display: flex;
  width: 78%;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: left;
}

.products {
  width: 30%;
  margin: 10px;
}

.summary {
  width: 30%;
}

.btn-primary{
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  text-transform: uppercase;
  background-color: rgb(193, 116, 8);
  color: rgb(193, 116, 8) !important;
}

.btn.show{
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  text-transform: uppercase;
  background-color: rgba(174, 97, 10, 0.208) !important;
  color: rgb(193, 116, 8) !important;
}

.btn.show {
  background-color: rgba(174, 97, 10, 0.208) !important;
}

.btn.show :hover{
  background-color: rgba(174, 97, 10, 0.208) !important;
}
/* 
.dropdown :hover{
  background-color: rgba(174, 97, 10, 0.208) !important;
} */

.dropdown-toggle{
  background-color: transparent !important;
}

/* Media Queries */
@media (max-width: 771px) {
  .filters {
    width: 40%;
    padding: 10px;
    margin: 5px;
    height: 100%;
  }

  .filters > span {
    font-size: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .productContainer {
    width: 58%;
    padding: 0;
  }

  .search {
    display: none !important;
  }

  .products {
    width: 100%;
  }
  
  .separator{
    width: 300px;
    height: 1px;
    background: rgba(0,0,0,0.2);
    margin: 20px auto;
  }
  
  .paypal{
      display: grid;
      gap: 20px;
  }
  
  .paypal-button-container{
      width: fit-content;
      margin: 0 auto;
  }
}


/* header styles

.header {
  display: block;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 10px;
}

.cartItemImg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.cartImage img{
  height: 8vh;  
}

.cartItemDetail {
  display: flex;
  flex: 1;
  padding: 0 10px;
  flex-direction: column;
}

.cartImage{
  cursor: pointer;
  margin-right: 10px;
}

.badge{
  padding: 0 3px;
  background-color: rgb(193, 116, 8);
  color: rgb(193, 116, 8);
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
  position: relative;
  top: -50px;
  right: -40px;
  width: 1.0rem;
}

/* Home Page */
/*
.home {
  display: flex;
}

.filters {
  background-color: #343a40;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
  height: 86vh;
}

.filters > span {
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
}

.productContainer {
  display: flex;
  width: 78%;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.products {
  width: 30%;
  margin: 10px;
}

.summary {
  width: 30%;
}

/* Media Queries */
/*
@media (max-width: 771px) {
  .filters {
    width: 40%;
    padding: 10px;
    margin: 5px;
  }

  .filters > span {
    font-size: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .productContainer {
    width: 58%;
    padding: 0;
  }

  .search {
    display: none !important;
  }

  .products {
    width: 100%;
  }
} */
